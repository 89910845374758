import HeadingAtom from 'atoms/headingAtom'
import gsap from 'gsap'
import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'

export default function MobileView({ data }: { data: any }) {
  const [activeIdx, setActiveIdx] = useState(1)
  const [isOpen, setIsOpen] = useState(true)
  const handleClick = (index: number) => {
    if (isOpen) {
      setActiveIdx(index)
    } else {
      setActiveIdx(index)
    }
  }
  return (
    <div className="bg-valuecard blade-top-padding-xl blade-bottom-padding-xl sm:hidden block">
      <div className="w-container-sm relative blade-bottom-padding-xl">
        <HeadingAtom
          title="From Problems to Possibilities"
          subtitle="Solutions in Action"
          themeDark={false}
          className="max-w-xl text-left mb-6"
        />
        {data.map((item: any, index: number) => {
          return (
            <div key={item.id}>
              <div className="flex-1 py-2 grid gap-4 border-b-1 border-black border-opacity-20">
                <div className="flex gap-2 justify-between py-2">
                  <button
                    type="button"
                    onClick={() => handleClick(index)}
                    className={`${
                      activeIdx === item.id
                        ? 'text-opacity-100'
                        : 'text-opacity-70'
                    } p-0 text-left text-base flex justify-between flex-1 text-black font-semibold leading-snug`}
                  >
                    {item.title}

                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      className={`${
                        activeIdx === index ? 'rotate-180' : 'rotate-0'
                      } transition-all duration-700 h-6 w-6`}
                    >
                      <path fill="none" d="M0 0h24v24H0V0z" />
                      <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                    </svg>
                  </button>
                </div>

                <CSSTransition
                  in={activeIdx === index}
                  timeout={1000}
                  unmountOnExit
                  onEnter={(node: any) =>
                    gsap.fromTo(
                      node,
                      { height: 0 },
                      { height: 'auto', duration: 0.5 },
                    )
                  }
                  onExiting={(node: any) =>
                    gsap.to(node, { height: 0, duration: 0.5 })
                  }
                >
                  <div className="overflow-hidden bg- rounded-b-xl">
                    <div className="py-4">
                      <img
                        className="h-44"
                        src={item.img}
                        alt={`${item.title}`}
                      />
                    </div>
                    <h6 className="text-base text-black text-opacity-80">
                      {item.subtitle}
                    </h6>

                    <ul className="text-xl list-disc list-inside pt-2 pb-5">
                      {item.ul.map((elem: any) => {
                        return (
                          <li
                            key={item.id + 1}
                            className="text-black text-opacity-70 text-base"
                          >
                            {elem.li}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </CSSTransition>

                {/* <div
                  className={` ${
                    activeIdx === item.id
                      ? 'h-auto max-h-[9999px] duration-1000 delay-150'
                      : 'max-h-0'
                  } px-1 duration-200 transition-all ease-in-out overflow-hidden`}
                >
                  <div className="py-4">
                    <img
                      className="h-44"
                      src={item.img}
                      alt={`${item.title}`}
                    />
                  </div>
                  <h6 className="text-base text-black text-opacity-80">
                    {item.subtitle}
                  </h6>

                  <ul className="text-xl list-disc list-inside pt-2 pb-5">
                    {item.ul.map((elem: any) => {
                      return (
                        <li
                          key={item.id + 1}
                          className="text-black text-opacity-70 text-base"
                        >
                          {elem.li}
                        </li>
                      )
                    })}
                  </ul>
                </div> */}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
