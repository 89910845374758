import React from 'react'

export default function Banner() {
  return (
    <section className='bg-black min-h-[480px] md:min-h-[550px] bg-right-top bg-opacity-50 md:bg-contain  bg-[length:auto_60%] bg-no-repeat lg:h-screen bg-[url("assets/globals/bg-element-top-right.png")]'>
      <div className="w-container-xs min-h-[480px] md:min-h-[550px]  h-full  ">
        <div className="flex flex-col min-h-[480px] md:min-h-[550px] md:pb-10 md:pt-0 pt-10 justify-center items-center md:px-4 h-full">
          <h4 className="text-xl opacity-50 text-white">Services</h4>
          <h1 className="text-center mt-3 font-semibold text-gradient-dark text-transparent bg-clip-text leading-none">
            From Vision to Revolution: <br />
          </h1>
          <h1 className="text-center font-semibold opacity-50 text-gradient-dark text-transparent bg-clip-text leading-none">
            Your Evolution Starts Here!
          </h1>
        </div>
      </div>
    </section>
  )
}
