import React from 'react'
import logo from 'assets/globals/logo.svg'
import { TextAnchor, Anchor, ExternalAnchor } from 'atoms/links'

export default function Footer() {
  return (
    <footer className="text-white border-t-gray border-t border-opacity-10 bg-gradient-to-tr from-[#151515] from-0% via-20% to-black  to-100% blade-top-padding-lg blade blade-bottom-padding-lg">
      <div className="w-container-xl">
        <div className="flex lg:gap-20 lg:flex-row flex-col justify-between">
          <div className="flex flex-col">
            <Anchor href="/">
              <img
                src={logo}
                className="h-10 md:h-12 xlg:h-12"
                alt="Innogent logo"
                title="logo"
              />
            </Anchor>
          </div>

          <div className="flex lg:flex-row flex-col items-start md:pr-10 md:gap-20 gap-5 lg:mt-0 mt-8">
            <ul className="grid md:gap-7 gap-5  text-white list-none w-full">
              <li className="w-full block">
                <TextAnchor
                  size="base"
                  color="light"
                  href="/work"
                  text="Work"
                  className="block font-semibold uppercase text-opacity-90"
                />
              </li>
              <li className="w-full block">
                <TextAnchor
                  size="base"
                  color="light"
                  href="/services"
                  text="Services"
                  className="block font-semibold uppercase text-opacity-90"
                />
              </li>
              <li className="w-full block">
                <TextAnchor
                  size="base"
                  color="light"
                  href="/contact-us"
                  text="Contact Us"
                  className="block font-semibold uppercase text-opacity-90"
                />
              </li>
            </ul>

            <ul className="grid md:gap-7 gap-5  text-white list-none">
              <li className="font-semibold uppercase text-opacity-90">
                Solutions
              </li>
              <li>
                <TextAnchor
                  color="light"
                  size="small"
                  className="block whitespace-nowrap"
                  href="/solutions/product-development"
                  text="Product Development / Scaling"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  size="small"
                  className="block whitespace-nowrap"
                  href="/solutions/business-process-transformation"
                  text="Business Process Transformation"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  size="small"
                  className="block whitespace-nowrap"
                  href="/solutions/engineering-sustainability"
                  text=" Engineering Sustainability"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  size="small"
                  className="block whitespace-nowrap"
                  href="/solutions/business-innovation"
                  text="Business Innovation"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  size="small"
                  className="block whitespace-nowrap"
                  href="/solutions/data-management"
                  text="Data Management"
                />
              </li>
            </ul>
            {/* <ul className="grid md:gap-7 gap-5  text-white list-none">
              <li className="uppercase font-bold">Services</li>
              <li>
                <TextAnchor
                  color="light"
                  href="/coming-soon"
                  text="Consulting"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  href="/coming-soon"
                  text="Custom Software Development"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  href="/coming-soon"
                  text="Team Augmentation"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  href="/coming-soon"
                  text="Lean Product Development"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  href="/coming-soon"
                  text="Engineering Excellence"
                />
              </li>
            </ul> */}
            <ul className="grid md:gap-7 gap-5  text-white list-none">
              <li className="font-semibold uppercase text-opacity-90">
                Company
              </li>
              {/* <li>
                <TextAnchor color="light"
                className="block whitespace-nowrap"
                size="small"
                href="/services" text="Services" />
              </li>
              <li>
                <TextAnchor color="light"
                className="block whitespace-nowrap"
                size="small"
                href="/coming-soon" text="Work" />
              </li> */}
              <li>
                <TextAnchor
                  color="light"
                  className="block whitespace-nowrap"
                  size="small"
                  href="/about"
                  text="About "
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  className="block whitespace-nowrap"
                  size="small"
                  href="/culture"
                  text="Culture"
                />
              </li>
              {/* <li>
                <TextAnchor
                  color="light"
className="block whitespace-nowrap"
size="small"
                  
                  href="/coming-soon"
                  text="Contact Us"
                />
              </li> */}
              <li>
                <TextAnchor
                  color="light"
                  className="block whitespace-nowrap"
                  size="small"
                  href="/coming-soon"
                  text="Blogs"
                />
              </li>
            </ul>
          </div>
        </div>

        <div className=" flex gap-3 md:flex-row flex-col  justify-between items-center blade-top-padding-lg blade-top-margin">
          {/* <div className="flex gap-4 ">
            <TextAnchor
              color="light"
              text="Privacy Policy"
              href="/coming-soon"
            />
            <span className="opacity-60">|</span>
            <TextAnchor
              color="light"
              text="Terms and Conditions"
              href="/coming-soon"
            />
          </div> */}

          <h6 className="md:block hidden text-sm text-white whitespace-nowrap">
            ©2023. All Rights Reserved.
          </h6>

          <div className="flex items-center gap-4">
            <ExternalAnchor href="https://www.linkedin.com/company/innogent-in">
              LinkedIn
            </ExternalAnchor>
            <span className="opacity-60">|</span>
            <ExternalAnchor href="https://www.facebook.com/innogenttechnologies">
              Facebook
            </ExternalAnchor>
            {/* <ExternalAnchor href={process.env.REACT_APP_TWITTER ?? '#'}>
              Twitter
            </ExternalAnchor> */}
            {/* 
            <span className="opacity-60">|</span>
            
            <ExternalAnchor href={process.env.REACT_APP_MEDIUM ?? '#'}>
              Medium
            </ExternalAnchor> */}
          </div>

          <h6 className="md:hidden block text-sm text-white whitespace-nowrap">
            ©2023. All Rights Reserved.
          </h6>
        </div>
      </div>
    </footer>
  )
}
