import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'

import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HeroBtn } from 'atoms/buttons'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type FormProps = {
  isPurposeField?: boolean
  formTitle?: string
}

const formSchema = z.object({
  name: z
    .string()
    .min(1, 'Name is required.')
    .max(30, 'Name can not be more than 30 characters.'),
  email: z
    .string()
    .email('Invalid email address.')
    .min(1, 'Email is required.'),
  company: z.string().min(2, 'Company is required.'),
  message: z.string(),
})

export type FormFieldSchema = z.infer<typeof formSchema>

export default function Form({ formTitle = '' }: FormProps) {
  const [isLoading, setLoading] = useState(false)

  const formRef = useRef<any>()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormFieldSchema>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit: SubmitHandler<FormFieldSchema> = async (data) => {
    setLoading(true)
    const res = await fetch('')
    if (res.ok) {
      emailjs
        .sendForm(
          'innogent-service-id',
          'template_d9refd8',
          formRef.current,
          'mG9ssGJLNqKO0OSWG',
        )
        .then(
          (result) => {
            toast('Form submitted succesfully!')
            console.log(result.text)
          },
          (error) => {
            toast('Something went wrong!')
            console.log(error.text)
          },
        )
      console.log('Form has been submitted')
    } else {
      console.error('Form error')
    }
    reset()
    setLoading(false)
  }

  return (
    <section className="">
      <ToastContainer
        position="bottom-right"
        theme="dark"
        progressClassName="bg-subfooter"
        className="text-sm !px-4"
      />
      <div>
        <form
          noValidate
          ref={formRef}
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-screen-sm mx-auto w-full grid mt-4  2xl:gap-7 xl:gap-4 gap-4"
        >
          <div className="relative">
            <input
              className="input-style-light"
              id="name"
              placeholder="Full Name"
              {...register('name')}
            />
            {errors.email && (
              <span className="absolute top-full left-0 text-rose-500  2xl:text-sm text-[12px]">
                {errors.name?.message}
              </span>
            )}
          </div>

          <div className="relative">
            <input
              className="input-style-light"
              id="email"
              type="email"
              inputMode="email"
              placeholder="Email Id"
              {...register('email')}
            />
            {errors.email && (
              <span className="absolute top-full left-0 text-rose-500  2xl:text-sm text-[12px]">
                {errors.email?.message}
              </span>
            )}
          </div>
          <div className="relative">
            <input
              className="input-style-light"
              type="text"
              id="company"
              inputMode="text"
              placeholder="Company"
              {...register('company')}
            />
            {errors.company && (
              <span className="absolute top-full left-0 text-rose-500  2xl:text-sm text-[12px]">
                {errors.company?.message}
              </span>
            )}
          </div>

          <div>
            <textarea
              rows={4}
              id="message"
              className="input-style-light"
              placeholder="Got something to say? We're all ears!"
              {...register('message')}
            />
          </div>
          <div className="grid relative place-content-start">
            <HeroBtn
              type="submit"
              text={isLoading ? 'Loading...' : 'Submit'}
              isDisabled={isLoading}
              size="small"
              role="link"
            />
          </div>
        </form>
      </div>
    </section>
  )
}
