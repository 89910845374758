import React from 'react'
import { HeroBtn } from 'atoms/buttons'
// eslint-disable-next-line

export default function Blogcard({ data }: { data: any }) {
  const { title, thumbnail, categories, link } = data
  const goToSource = () => window.open(link, '_blank')
  return (
    <article className=" mx-auto max-w-md w-full h-full self-stretch flex flex-col gap-1  border-[2px] rounded-lg p-3  md:p-3 lg:p-6 border-solid border-glass border-opacity-25">
      <div className="h-56">
        <img
          className="h-full w-full object-cover object-center rounded-md"
          src={thumbnail}
          alt={title}
        />
      </div>
      <div className=" pt-2 md:pt-4  gap-2 flex-1 flex flex-col  pb-3">
        <small className="whitespace-nowrap text-gray line-clamp-1">
          {categories[0]}
        </small>
        <div className="line-clamp-2 pb-7 md:pb-9">
          <h6 className="text-small font-semibold leading-snug text-white w-11/12  ">
            {title}
          </h6>
        </div>

        <div className="grid place-content-start">
          <HeroBtn
            size="small"
            type="button"
            onClick={goToSource}
            text="Know More"
          />
        </div>
      </div>
    </article>
  )
}
