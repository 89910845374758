import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import {
  PageHome,
  PageError404,
  PageAbout,
  PageContact,
  PageBlog,
  PageTermsAndConditions,
  PagePrivacyPolicy,
  PageCulture,
  PageBusinessInnovation,
  PageBusinessProcess,
  PageEngineeringSustainability,
  PageProductDevelopment,
  PageDataManagement,
} from 'screens'
import { Header, Footer, ScrollToTopOnLoad } from 'organisms'
import PageServices from 'screens/services'

function ViewWithHeaderAndFooter() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}

export default function routes() {
  return [
    {
      path: '/',
      element: <ViewWithHeaderAndFooter />,
      children: [
        {
          path: '/',
          element: <PageHome />,
        },
        {
          path: '/contact-us',
          element: <PageContact />,
        },
        {
          path: '/about',
          element: <PageAbout />,
        },
        {
          path: '/solutions/product-development',
          element: <PageProductDevelopment />,
        },
        {
          path: '/solutions/business-innovation',
          element: <PageBusinessInnovation />,
        },
        {
          path: '/solutions/business-process-transformation',
          element: <PageBusinessProcess />,
        },
        {
          path: '/solutions/data-management',
          element: <PageDataManagement />,
        },
        {
          path: '/solutions/engineering-sustainability',
          element: <PageEngineeringSustainability />,
        },
        {
          path: '/culture',
          element: <PageCulture />,
        },
        {
          path: '/blogs',
          element: <PageBlog />,
        },

        {
          path: '/terms-and-conditions',
          element: <PageTermsAndConditions />,
        },
        {
          path: '/privacy-policy',
          element: <PagePrivacyPolicy />,
        },
        {
          path: '/services',
          element: <PageServices />,
        },
        {
          path: '*',
          element: <PageError404 />,
        },
      ],
    },
  ]
}
