import React from 'react'
import HeadingAtom from 'atoms/headingAtom'
import heroMedia from 'assets/home/banner.mp4'
import poster from 'assets/home/bannerPoster.jpg'

export default function Banner() {
  return (
    <section className="bg-black md:mt-0 blade-top-padding relative md:min-h-screen min-h-[500px]">
      <video
        className="absolute -z-1 top-0 h-full w-full object-cover object-bottom"
        poster={poster}
        autoPlay
        muted
        loop
        playsInline
      >
        <source src={heroMedia} type="video/mp4" />
        <track kind="captions" />
      </video>

      <div className="w-full absolute top-0 md:min-h-screen min-h-[450px] ">
        <div className="z-50 w-container-sm md:min-h-screen min-h-[450px] flex flex-col place-content-center md:pb-32">
          <HeadingAtom
            title="Conquering Business Challenges"
            subtitle="To Drive Transformation & Product Breakthroughs"
            className="2xl:max-w-2xl xl:max-w-xl max-w-md"
          />
        </div>
      </div>
    </section>
  )
}
