import React, { useEffect } from 'react'
import SubFooter from 'organisms/subFooter'
import { useNavigate } from 'react-router-dom'
import StoriesOfTransformation from './storiesofTransformation'
import SubstainableTransformation from './sustainableTransformation'
// import './index.css'
import Possibilities from './possibilities/possibilities'
import Implementation from './implementation'
import Banner from './banner'

export default function PageHome() {
  const navigate = useNavigate()
  const goToContact = () => navigate('/contact-us')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="bg-black">
      <Banner />
      <Implementation />
      <Possibilities />
      <SubstainableTransformation />
      <StoriesOfTransformation />
      <SubFooter
        title='Let&rsquo;s Redefine <br class="md:block hidden" />Possibilities Together!'
        cta={[
          {
            type: 'primary',
            text: 'Schedule a consulation',
            onClick: goToContact,
            color: 'black',
          },
          {
            color: 'transparent',
            type: 'secondary',
            text: 'Request a proposal',
            onClick: goToContact,
          },
        ]}
      />
    </div>
  )
}
