import React from 'react'

export default function LimitlessPossibilities() {
  return (
    <section className="limitless-possibilities bg-bladeGradient blade-top-padding-xl blade-bottom-padding-xl">
      <div className="w-container-sm max-w-4xl">
        <h2 className=" text-black font-medium capitalize">
          Enter into a realm of <br className="md:block hidden" />
          <span className="md:ml-52  ">limitless possibilities! </span>
        </h2>

        <h5 className="md:ml-52  2xl::my-24 md:my-8 my-3 font-semibold">
          And you’ll witness boundaries being mere illusions,{' '}
          <br className="md:block hidden" />
          and the pursuit of brilliance knowing no limits.”
        </h5>
      </div>

      <div className="w-container md:pt-10 pt-3 md:flex justify-between gap-10">
        <h5 className="font-semibold 2xl:max-w-sm lg:max-w-[280px] ">
          We are a sanctuary where folks gracefully embrace the art of boundless
          imagination and fearlessly explore the uncharted with an undying
          longing for real frugal innovation.
        </h5>
        <div className="grid gap-10 opacity-80 max-w-4xl md:mt-0 mt-8">
          <h5>
            Time is not linear in our universe; there&apos;s no stark contrast
            between who we are today and who we will be tomorrow. We cherish
            every moment, savoring our uniqueness as it evolves naturally.
            Perhaps the reason we choose joy and passion as our sole pursuits
            and when you do that, there&apos;s nothing that can hold you back.
          </h5>
          <h5>
            Every day, our walls bear witness to contagious energy and echoes of
            lighthearted banter, especially our Billiards and TT corners. Here,
            being your authentic self is not just welcomed but celebrated, as we
            encourage you to cut yourself some slack and let loose.
          </h5>
          <h5>
            For us, work is more than a chore and we have mastered the art of
            making it a delightful drill where inspirations flow freely.
            Hierarchical barriers crumble as we come together in power-packed,
            engrossing townhalls, embracing an egalitarian spirit that empowers
            every team member to curate a tapestry of ideas, with each thread
            contributing to a symphony of growth, excellence, and creativity.
          </h5>
        </div>
      </div>
    </section>
  )
}
