import React from 'react'

const sizeMap = {
  small: 'text-sm px-6 py-3',
  base: 'lg:text-base text-sm px-5 lg:px-8 py-4',
  large: ' text-base md:text-lg',
  xl: ' text-base md:text-xl',
  xxl: ' text-xl xl:text-2xl',
  '3xl': ' text-xl md:text-2xl xl:text-3xl',
}
const colorMap = {
  transparent:
    '!bg-transparent text-dark stroke-dark group-hover:stroke-white group-hover:text-white group-hover:border-transparent border border-black',
  black: '!bg-mirror text-white stroke-white border-transparent',
  blue: '!bg-blue text-white stroke-white border-transparent',
}

type MasterBtnProps<T extends 'submit' | 'button'> = {
  text: string
  size: keyof typeof sizeMap
  color?: keyof typeof colorMap
  type: T
  isDisabled?: boolean
  role?: 'button' | 'link'
  onClick?: T extends 'submit' ? never : () => void
}

const spaceMap = {
  small: 'w-11 h-11',
  base: 'w-14 h-14',
}
export function HeroBtn<T extends 'submit' | 'button'>({
  type,
  role = 'button',
  text,
  size,
  isDisabled,
  color = 'black',
  ...rest
}: MasterBtnProps<T>) {
  let space: 'base' | 'small' = 'base'
  let isSmall = true
  if (size === 'base' || size === 'small') {
    space = size
  }
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  const spaceClass: string = spaceMap[space]
  if (size === 'base') isSmall = false

  return (
    <button
      role={role}
      disabled={isDisabled}
      {...rest}
      type={type === 'button' ? 'button' : 'submit'}
      className="flex gap-1 group relative outline-none"
    >
      <div
        className={`absolute ${
          isSmall ? 'left-12' : 'left-14'
        } right-auto top-0 bottom-0 scale-90 group-hover:scale-100 group-focus:scale-100  
        group-focus:opacity-100 opacity-0 group-hover:opacity-100 group-hover:!bg-blue group-focus:!bg-blue
        transition-all duration-500 ease-in-out group-focus:left-0  group-hover:left-0 ${spaceClass} self-stretch 
        min-h-full rounded-full grid place-content-center bg-inherit ${colorClass}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="inherit"
          className="w-7 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
          />
        </svg>
      </div>

      <div
        className={`group-hover:!bg-blue group-focus:!bg-blue z-10 h-full ${
          isSmall
            ? 'group-hover:translate-x-12 group-focus:translate-x-12'
            : 'group-hover:translate-x-[60px] group-focus:translate-x-[60px]'
        } whitespace-nowrap  transition-all duration-700 ease-in-out 
        ${sizeClass}  ${colorClass} rounded-full group-hover:border-transparent font-normal tracking-wide leading-snug`}
      >
        {text}
      </div>

      <div
        className={`group-hover:!bg-blue border-[1px] border-solid  group-hover:border-transparent group-focus:border-transparent group-focus:!opacity-0 group-hover:!opacity-0 group-hover:scale-100  group-focus:scale-100 group-hover:-translate-x-14  transition-all duration-500 ease-in-out  ${spaceClass} self-stretch min-h-full rounded-full grid place-content-center bg-inherit ${colorClass}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="inherit"
          className="w-7 h-6 "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
          />
        </svg>
      </div>
    </button>
  )
}
