import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, EffectCreative } from 'swiper'
import 'swiper/css'
import HeadingAtom from 'atoms/headingAtom'
import david from 'assets/home/testimonials/david.png'
import vincent from 'assets/home/testimonials/vincent.png'
import rinaldo from 'assets/home/testimonials/rinaldo.png'

const data = [
  {
    name: 'David  ',
    designation: 'OnPlan CEO',
    profileImage: david,
    value: `Innogent has been an extraordinary partner since 2018. The professionalism and competence of the team in software engineering have been invaluable. They're not just developers, but invested stakeholders in our success. With Innogent, we're well-equipped to tackle any challenges that come our way, and I see a bright future for both our organizations in revolutionizing Financial Planning and Analysis.`,
  },
  {
    name: 'Vincent',
    // of a Germany-based company specializing in route optimization software for Logistics Enterprises
    designation: 'Flutaro CEO',
    profileImage: vincent,
    value: `Innogent Technologies has been instrumental in realizing our vision for route optimization software. The team’s client-centric approach and deep industry understanding expedited development and elevated my product. They're not just service providers; they're genuine technology partners. I look forward to further breakthroughs in our partnership in the future.`,
  },
  {
    name: 'Rinaldo Bonazzo',
    designation: 'BFlows CTO',
    profileImage: rinaldo,
    value: `For 4 years, Innogent Technologies has driven innovation and mutual success. The team’s expertise has optimized our processes and delivered cutting-edge solutions, catalyzing our growth. A mix of tech enthusiasts and seasoned professionals, always eager to assist, adapt and move forward, they not only understand our needs, but anticipate challenges, proving time and time again that they are genuine true partners. Looking ahead, we are excited about the future prospects of our partnership. `,
  },
]

type ITestimonial = (typeof data)[0]

export default function StoriesOfTransformation() {
  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <section className=" bg-black ">
      <div className=" blade-bottom-padding-lg w-container-sm">
        <div className="text-center  blade-top-padding grid gap-2">
          <HeadingAtom
            title="Stories of Transformation!"
            subtitle="Enabling leaders to do better in pursuit of the extraordinary, no matter the starting point."
            className="max-w-4xl mx-auto"
            subTitleClass="max-w-xl mx-auto"
          />
        </div>
        <div className="stories-carousel  blade-top-padding-lg blade-bottom-padding relative max-w-5xl mx-auto">
          <div className="absolute sm:inset-0 inset-x-0 bottom-0 flex items-center sm:justify-between justify-center gap-2 z-20">
            <button
              type="button"
              disabled={activeIndex === 0}
              className={`${
                activeIndex === 0 ? 'opacity-30 ' : 'opacity-100'
              } hover:stroke-white   disabled:cursor-not-allowed  stroke-gray prev-btn p-3 rounded-full border-1 border-opacity-10 border-white hover:border-yellow bg-mirror  flex items-center justify-center`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="inherit"
                className="w-6 h-6 stroke-inherit"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </button>
            <button
              type="button"
              disabled={activeIndex === data.length - 1}
              className={`${
                activeIndex === data.length - 1 ? 'opacity-30 ' : 'opacity-100'
              } hover:stroke-white   disabled:cursor-not-allowed stroke-gray next-btn p-3 rounded-full border-1 border-opacity-10 border-white hover:border-yellow bg-mirror  flex items-center justify-center`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="inherit"
                className="w-6 h-6 stroke-inherit"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div>
          <Swiper
            autoHeight
            navigation={{
              nextEl: '.next-btn',
              prevEl: '.prev-btn',
            }}
            speed={1000}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            modules={[Navigation, EffectCreative]}
            spaceBetween={50}
            loop={false}
            slidesPerView={1}
            className="pb-10 px-1"
          >
            {data.map((elem: ITestimonial, index) => {
              const key = `${index}`
              return (
                <SwiperSlide key={key}>
                  <TestimonialSlide testimonial={elem} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

function TestimonialSlide({ testimonial }: { testimonial: ITestimonial }) {
  const { name, value, profileImage, designation } = testimonial
  return (
    <article className="grid place-content-center place-items-center gap-4 text-white bg-black">
      <h6 className="text-lg max-w-2xl text-center">{value}</h6>
      <div className="flex items-center gap-3  mt-6  lg:mt-8 xl:mt-12">
        <div className="h-20 w-20 rounded-full overflow-hidden">
          <img
            src={profileImage}
            alt={name}
            loading="lazy"
            className="object-top"
          />
        </div>
        <div className="grid gap-1">
          <span className="font-semibold">{name} </span>
          <small className="text-gray">{designation} </small>
        </div>
      </div>
    </article>
  )
}
