import React, { ReactNode } from 'react'
import { NavLink, Link } from 'react-router-dom'

const sizeMap = {
  small: 'text-sm',
  base: 'text-base',
  large: ' text-base md:text-lg',
  xl: ' text-base md:text-xl',
  xxl: ' text-xl xl:text-2xl',
  '3xl': ' text-xl md:text-2xl xl:text-3xl',
}

const colorMap = {
  dark: 'text-black',
  light: 'text-white',
}
type MasterAnchorProps = {
  size?: keyof typeof sizeMap
  text: string
  href: string
  target?: '_self' | '_blank'
  className?: string
}

export function TextAnchor({
  text,
  target,
  href,
  color = 'dark',
  size = 'base',
  className,
  ...rest
}: MasterAnchorProps & { color?: 'dark' | 'light' }) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  return (
    <Link
      target={target}
      to={href}
      {...rest}
      className={`${sizeClass} ${colorClass} ${className} text-opacity-70 hover:text-opacity-100 focus:outline-none 
                  focus:underline decoration-from-font underline-offset-4 focus:text-opacity-100`}
    >
      {text}
    </Link>
  )
}

export function TextNavAnchor({
  text,
  target,
  href,
  color = 'dark',
  size = 'base',
  className,
  ...rest
}: MasterAnchorProps & { color?: 'dark' | 'light' }) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  return (
    <NavLink
      target={target}
      to={href}
      {...rest}
      className={(navData) => {
        return `${
          navData.isActive ? 'opacity-100' : 'opacity-60'
        } ${className} ${sizeClass}  ${colorClass} hover:opacity-80 transition-all duration-300`
      }}
    >
      {text}
    </NavLink>
  )
}

export function HeroAnchor({
  text,
  size = 'base',
  href,
  target,
  ...rest
}: MasterAnchorProps) {
  const sizeClass: string = sizeMap[size]
  return (
    <Link to={href} target={target} {...rest} className={`${sizeClass} `}>
      {text}
    </Link>
  )
}
// control the outline of anchor
export function Anchor({
  href,
  children,
  className = '',
}: {
  children: ReactNode
  className?: string
  href: string
}) {
  return (
    <Link className={className} to={href}>
      {' '}
      {children}
    </Link>
  )
}

export function ExternalAnchor({
  href,
  children,
  className,
}: {
  href: string
  className?: string

  children: ReactNode
}) {
  return (
    <a
      className={className}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}
