import React from 'react'

export default function Banner() {
  return (
    <section className='bg-black min-h-[480px] md:min-h-[550px] md:bg-right-bottom bg-elementPosition bg-cover bg-no-repeat lg:h-screen bg-[url("assets/globals/about_elements.png")]'>
      <div className="w-container-sm min-h-[480px] md:min-h-[550px]  h-full  ">
        <div className="flex flex-col min-h-[480px] md:min-h-[550px] md:pb-10 md:pt-0 pt-10 gap-3 justify-center md:px-4  h-full">
          <h5 className="font-medium leading-tight text-gray">Vision</h5>
          <h1 className=" font-semibold text-gradient-dark text-transparent bg-clip-text leading-none">
            To create possibilities, and <br className="md:block hidden" />
            bring transformational impact <br className="md:block hidden" />
            with lean way of innovation, <br className="md:block hidden" />{' '}
            excellence, and integrity.
          </h1>
        </div>
      </div>
    </section>
  )
}
