import React from 'react'
import jerrad from 'assets/about/Jerrod Gladden.jpg'

const team = [
  {
    profileImage: jerrad,
    name: 'Jerrod Gladden',
    designation: 'Executive Advisor',
    linkedinProfile: 'https://www.linkedin.com/in/jerrodgladden ',
  },
]

export default function Investors() {
  return (
    <section className="bg-black">
      <div className="text-center blade-top-padding blade-bottom-padding">
        <h2 className="bg-subfooter text-transparent bg-clip-text font-semibold">
          Our Advisor
        </h2>
      </div>
      <div className="grid sm:grid-cols-2 md:pt-5  grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4  w-container   gap-y-8 lg:gap-y-16 gap-x-6 px-10 blade-bottom-padding">
        {team.map((elem, index) => {
          const key = `${index}`
          return <Investorcard data={elem} key={key} />
        })}
      </div>
    </section>
  )
}

type FoundingMember = {
  data: {
    profileImage: string
    name: string
    designation: string
    linkedinProfile: string
  }
}

function Investorcard({ data }: FoundingMember) {
  const { profileImage, name, designation, linkedinProfile } = data
  return (
    <article>
      <div className="md:h-[28rem] h-[20rem] relative oveflow-hidden grayscale hover:grayscale-0 transition duration-500">
        <div className="absolute inset-0 pb-2 pr-2 grid place-content-end">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={linkedinProfile}
            className="p-3 outline-none  opacity-70 hover:opacity-100  focus:opacity-100 rounded-full focus:bg-white bg-opacity-5 fill-white focus:fill-dark "
          >
            <svg
              width={18}
              height={18}
              viewBox="0 0 18 18"
              fill="inherit"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V17C0 17.2652 0.105357 17.5196 0.292893 17.7071C0.48043 17.8946 0.734784 18 1 18H17C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V1C18 0.734784 17.8946 0.48043 17.7071 0.292893C17.5196 0.105357 17.2652 0 17 0ZM5.339 15.337H2.667V6.747H5.339V15.337ZM4.003 5.574C3.59244 5.574 3.1987 5.41091 2.9084 5.1206C2.61809 4.8303 2.455 4.43655 2.455 4.026C2.455 3.61544 2.61809 3.22171 2.9084 2.9314C3.1987 2.64109 3.59244 2.478 4.003 2.478C4.41356 2.478 4.80729 2.64109 5.0976 2.9314C5.38791 3.22171 5.551 3.61544 5.551 4.026C5.551 4.43655 5.38791 4.8303 5.0976 5.1206C4.80729 5.41091 4.41356 5.574 4.003 5.574ZM15.338 15.337H12.669V11.16C12.669 10.164 12.651 8.883 11.281 8.883C9.891 8.883 9.68 9.969 9.68 11.09V15.338H7.013V6.748H9.573V7.922H9.61C9.965 7.247 10.837 6.535 12.134 6.535C14.838 6.535 15.337 8.313 15.337 10.627L15.338 15.337Z"
                fill="inherit"
              />
            </svg>
          </a>
        </div>
        <img
          src={profileImage}
          alt={name}
          className="h-full w-full object-cover md:object-top object-center rounded-xl"
        />
      </div>
      <div className="pt-4 pl-2 grid">
        <span className="font-semibold text-white">{name}</span>
        <small className="text-white">{designation} </small>
      </div>
    </article>
  )
}
