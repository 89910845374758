import React from 'react'
import HeadingAtom from 'atoms/headingAtom'
import { timeline } from 'static/ourStory'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

type IValue = (typeof timeline)[0]
export default function MobileTimeline() {
  return (
    <div className="md:hidden block blade-top-padding-xl blade-bottom-padding-xl">
      <div className="w-container">
        <HeadingAtom
          className="timeline-heading 2xl:max-w-4xl max-w-2xl text-left ml-auto blade-bottom-padding"
          title="While We Rewrite the Narratives of Possibilities,"
          subtitle="Here’s Our Story of Transformation"
        />
      </div>

      <div>
        {/* <h3 className="text-gradient-dark font-bold w-container">2016</h3> */}
        <Carousel />
        {/* <h3 className="text-gradient-dark font-bold w-container pt-14">2020</h3>
        <Carousel /> */}
      </div>
    </div>
  )
}

function Carousel() {
  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      slidesPerView={1.2}
      spaceBetween={20}
      breakpoints={{
        640: {
          slidesPerView: 1.5,
          spaceBetween: 20,
        },
        1020: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
      }}
      pagination
      autoplay={{
        delay: 3000,
      }}
      speed={500}
      className="px-5 md:pb-8 pb-10  mt-5"
    >
      <div className="min-h-full h-full self-stretch ">
        {timeline.map((elem: IValue, index) => {
          const key = `${index}`
          return (
            <SwiperSlide
              key={key}
              className="min-h-full h-full self-stretch border-none"
            >
              <div className="w-full">
                <img src={elem.img} alt={elem.title} />
              </div>
            </SwiperSlide>
          )
        })}
      </div>
    </Swiper>
  )
}
