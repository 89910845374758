import React from 'react'
import discovery from 'assets/home/values/discovery.svg'
import bespoke_strategies from 'assets/home/values/bespoke_strategies.svg'
import guiding_the_shift from 'assets/home/values/guiding_the_shift.svg'
import smooth_prolonged from 'assets/home/values/smooth_prolonged.svg'
import unveiling_strategic from 'assets/home/values/unveiling_strategic.svg'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import HeadingAtom from 'atoms/headingAtom'

const values = [
  {
    title: 'Discovery',
    desc: 'We layer down problem statements with a first principle approach to navigate through the maze of business challenges and opportunities.',
    cover: discovery,
  },
  {
    title: 'Unveiling Strategic Insights',
    desc: 'We harness the pulse of the industry, decode growth dynamics, identify your positioning, and reveal untapped potentials through data-driven precision analysis.',
    cover: unveiling_strategic,
  },
  {
    title: 'Bespoke Strategies',
    desc: 'Our experts cut through the noise to craft a tailor-made transformation roadmap equipping you to break barriers, seize opportunities, and foresee change.',
    cover: bespoke_strategies,
  },
  {
    title: 'Guiding the Shift',
    desc: 'We provide active assistance during the implementation stage, oversee advancements, and guide through any obstacles that may arise to ensure the progress stays on course.',
    cover: guiding_the_shift,
  },
  {
    title: 'Independence, Smoothly Sailed',
    desc: 'Paving the way for a sustainable transformation post-execution, we equip your team with the know-how to handle the reins confidently. ',
    cover: smooth_prolonged,
  },
]
type IValue = (typeof values)[0]

export default function SubstainableTransformation() {
  return (
    <section className="bg-black z-50">
      <div className="blade-top-padding-xl blade-bottom-padding-xl">
        <div className="blade-bottom-padding-lg w-11/12 sm:w-10/12 blade-top-margin-sm blade-bottom-margin-sm block ml-auto sm:mr-0 mr-auto">
          <HeadingAtom
            title="Your Journey to Sustainable Transformation"
            subtitle="Orchestrating with Precision"
            className="2xl:max-w-4xl max-w-3xl"
          />
        </div>
        <div className="lg:w-10/12 values-carousel pl-7 md:pl-20 ml-auto">
          <Swiper
            modules={[Autoplay, Pagination]}
            slidesPerView={1}
            spaceBetween={20}
            breakpoints={{
              640: {
                slidesPerView: 1.5,
                spaceBetween: 20,
              },
              1020: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              1440: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              1536: {
                slidesPerView: 2.8,
              },
            }}
            pagination={{
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true,
            }}
            autoplay={{
              delay: 3000,
            }}
            slideToClickedSlide
            autoHeight
            speed={1500}
            className="pb-10 pr-7 sm:pr-10 h-full"
          >
            <div className="min-h-full h-full self-stretch ">
              {values.map((elem: IValue, index) => {
                const key = `${index}`
                return (
                  <SwiperSlide
                    key={key}
                    className="min-h-full h-full self-stretch"
                  >
                    <ValueCard value={elem} />
                  </SwiperSlide>
                )
              })}
            </div>
            <span className="swiper-pagination gap-1 !bottom-0" />
          </Swiper>
        </div>
      </div>
    </section>
  )
}

function ValueCard({ value }: { value: IValue }) {
  const { title, desc, cover } = value
  return (
    <article className="bg-valuecard grid place-content-between lg:gap-20 sm:gap-10 gap-5 relative p-6 lg:p-12 min-h-full h-full">
      <div className="lg:w-11/12">
        <h4 className="font-semibold pb-3">{title}</h4>
        <h6 className="text-dark pr-5 ">{desc}</h6>
      </div>
      <div className="">
        <img
          src={cover}
          alt={title}
          loading="lazy"
          className="sm:h-44 h-28 w-auto ml-auto object-contain"
        />
      </div>
    </article>
  )
}
