import React, { Fragment, useState, useEffect } from 'react'
import { Placeholder, BlogCard } from 'molecules'

const data = [
  {
    id: 1,
    title: 'Exploring the Great Outdoors and Embracing Adventure',
    categories: ['Travel & Adventure'],
    description:
      'Experience the beauty of nature with thrilling outdoor adventures and make unforgettable memories.',
    thumbnail:
      'https://images.unsplash.com/photo-1446329813274-7c9036bd9a1f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDN8fG5hdHVyZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60',
  },
  {
    id: 2,
    title: 'Healthy Cooking for a Balanced Lifestyle',
    categories: ['Health & Wellness'],
    description:
      'Learn to cook delicious and nutritious meals for a healthier lifestyle and wellbeing.',
    thumbnail:
      'https://images.unsplash.com/photo-1446329813274-7c9036bd9a1f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDN8fG5hdHVyZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60',
  },
  {
    id: 3,
    title: 'Digital Marketing Strategies for Business Growth',
    categories: ['Business & Marketing'],
    description:
      'Discover effective digital marketing techniques to boost your business and reach a wider audience.',
    thumbnail:
      'https://images.unsplash.com/photo-1446329813274-7c9036bd9a1f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDN8fG5hdHVyZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60',
  },
  {
    id: 4,
    title: 'Mastering the Art of Photography and Visual Storytelling',
    categories: ['Creative Arts'],
    description:
      'Capture unforgettable moments with the artistry of photography and create compelling visual stories.',
    thumbnail:
      'https://images.unsplash.com/photo-1446329813274-7c9036bd9a1f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDN8fG5hdHVyZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60',
  },
]

export type BlogProps = (typeof data)[0] // remve in production. types need to be defined in a better way

export default function CardGrid() {
  const [blogs, setBlogs] = useState<any[]>([])
  const [isLoading, setLoading] = useState(true)
  const apiUrl =
    'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@charlielukas/'

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((resData) => {
        setBlogs(resData.items)
        setLoading(false)
      })
      .catch((error) => console.error('Error fetching data:', error))
  }, [])

  if (isLoading) {
    return <Placeholder text="Loading..." />
  }
  return (
    <Fragment key="cardGrid">
      <section className="max-w-[57rem] 2xl:max-w-none   px-3 xl:px-0 md:px-10 xl:w-[88%] mx-auto grid 2xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 md:gap-4 xl:gap-5">
        {blogs.map((elem, index) => {
          const key = `${index}`
          return <BlogCard data={elem} key={key} />
        })}
      </section>
    </Fragment>
  )
}
