import React from 'react'

export default function HeadingAtom({
  className,
  title,
  pageName,
  subtitle,
  themeDark = true,
  titleClass,
  subTitleClass,
}: {
  title: string
  pageName?: string
  subtitle: string
  className?: string
  themeDark?: boolean
  titleClass?: string
  subTitleClass?: string
}) {
  return (
    <div className={className}>
      {!themeDark ? (
        <>
          <h5
            className={`${subTitleClass}  font-medium text-darkGray md:mt-4 mt-2 leading-normal`}
          >
            {pageName}
          </h5>
          <h1
            className={`${titleClass} font-semibold leading-tight text-gradient-light`}
          >
            {title}
          </h1>
          <h5
            className={`${subTitleClass}  font-medium text-darkGray md:mt-4 mt-2 leading-normal`}
          >
            {subtitle}
          </h5>
        </>
      ) : (
        <>
          <h1
            className={`${titleClass} font-semibold  leading-tight text-gradient-dark`}
          >
            {title}
          </h1>
          <h5
            className={`${subTitleClass} font-light text-gray md:mt-4 mt-2 leading-normal`}
          >
            {subtitle}
          </h5>
        </>
      )}
    </div>
  )
}
