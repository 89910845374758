import React, { useEffect } from 'react'
import { ContactFormOrganism } from 'organisms/index'

export default function PageContact() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="md:mt-0 relative blade-top-padding blade-bottom-padding xlg:h-screen h-fit 2xl:min-h-[700px] lg:min-h-[600px] min-h-[700px] bg-no-repeat overflow-hidden flex items-center">
      {/* <GradientOverlay bgImage={bg} bgClass="bg-left-top bg-cover" /> */}
      <div className="w-full relative">
        <div className="z-50 w-container-sm flex gap-10 justify-between md:flex-row flex-col-reverse lg:pt-10 md:pt-10 pt-20">
          <div className="flex-1 h-fit grid gap-5 pb-10 2xl:w-6/12 md:w-5/12 w-full">
            <div>
              <h6 className="text-white text-opacity-50">Office</h6>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://maps.app.goo.gl/6GJMcVA1MQ8Gyi1cA"
              >
                <h6 className="text-white mt-2">
                  904-912, Shekhar Central, Palasia Square, <br /> Manorama
                  Ganj, Indore, 4520001 (MP), India
                </h6>
              </a>
            </div>
            <div>
              <h6 className="text-white text-opacity-50">Phone</h6>
              <a href="tel:9893043042" rel="noreferrer">
                <h6 className="text-white mt-2">+91 9893043042</h6>
              </a>
            </div>
            <div>
              <h6 className="text-white text-opacity-50">Email</h6>
              <a href="mailto:info@innogent.in" rel="noreferrer">
                <h6 className="text-white mt-2">info@innogent.in</h6>
              </a>
            </div>
          </div>
          <div className="flex-1 text-white 2xl:w-6/12 md:w-5/12 w-full">
            <div className="2xl:max-w-xl xlg:max-w-lg max-w-[400px] bg-black md:mx-0 mx-auto rounded-xl">
              <h3 className="text-gradient-dark text-40 text font-semibold">
                Let’s discuss a roadmap to your Business&apos;s Triumph.
              </h3>
              <h6 className="pt-4 text-white text-opacity-50">
                Talk to our Experts!
              </h6>
              <ContactFormOrganism />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
