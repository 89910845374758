import HeadingAtom from 'atoms/headingAtom'
import React from 'react'

export default function Transforming() {
  return (
    <section className="w-container-sm blade-top-padding-xl blade-bottom-padding-xl flex flex-wrap md:gap-20 gap-5 justify-between">
      <div className="flex-1 2xl:max-w-xl basis-56 xlg:max-w-lg max-w-sm">
        <HeadingAtom
          title="Transforming for Resilience"
          subtitle="Services Empowering You to Evolve, Adapt, & Conquer with Ingenuity"
        />
      </div>
      <div className="flex-1 grid gap-6 basis-56 2xl:max-w-xl max-w-lg">
        <h4 className="text-white">
          Shaping the paradigm of possibilities to shatter the limitations
          engulfing your business.
        </h4>
        <h4 className="text-white text-opacity-50">
          We stand as visionary creators. Our tale is one of unwavering
          dedication, wielding audacity as a compass to steer us towards
          uncharted territories of innovation.
        </h4>
        <h4 className="text-white text-opacity-50">
          The cornerstone of our approach stems from expert craftsmanship and
          strategic perspective to translate your visions into reality, empower
          your ideas with tangible business value, and enable you to cultivate a
          legacy of excellence that reverberates across industries.
        </h4>
      </div>
    </section>
  )
}
