import Accordion from 'atoms/accordion'
import React from 'react'

import { productDevelopment } from 'static/solutions'

export default function ProblemStatementMobileView({ data }: { data: any }) {
  return (
    <div className="w-container border-2">
      <h6 className="text-white ">{productDevelopment.paragraph}</h6>
      <h3 className="text-gradient-dark mt-4 text-40 font-semibold ">
        {productDevelopment.title}
      </h3>
      <h4 className="text-gradient-dark mt-4 pr-20">
        {productDevelopment.subtitle}
      </h4>

      <div className="mt-5">
        <Accordion items={data.list} />
      </div>
    </div>
  )
}
