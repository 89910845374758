import React from 'react'
import { featuresData } from 'static/services'

export default function SeismicShiftMobile() {
  return (
    <div className="md:hidden block bg-[url(assets/services/service_bg.jpg)] bg-contain bg-no-repeat bg-left-top md:pt-[40rem] pt-[15rem] blade-bottom-padding-xl">
      <div className="-mt-32 grid gap-6 px-4">
        {featuresData.map((item, index) => {
          return (
            <section
              key={item.title}
              className="bg-gradient w-container z-10 relative rounded-xl overflow-hidden lightGray mx-auto blade-top-padding-lg blade-bottom-padding-lg"
            >
              <div className="max-w-2xl text-center mx-auto">
                <h3 className="titleClass font-semibold leading-tight text-black">
                  {item.title}
                </h3>
                <h5 className="subTitleClass font-medium text-darkGray md:mt-4 mt-2 leading-normal">
                  {item.subtitle}
                </h5>
              </div>
              <div className="flex md:flex-row flex-col justify-around 2xl:px-40 lg:px-20  gap-10 mx-auto 2xl:pt-20 pt-5">
                <div className="flex-1 max-w-xl">
                  {item.paragraph ? (
                    <h5 className="font-semibold">{item.paragraph}</h5>
                  ) : (
                    ''
                  )}{' '}
                  <h6 className="mt-2">{item.subParagraph}</h6>
                </div>
                <div className="flex items-center justify-center lg:max-w-none max-w-[200px] mx-auto flex-1">
                  <img className="w-full" src={item.imgSrc} alt="" />
                </div>
              </div>
            </section>
          )
        })}
      </div>
    </div>
  )
}
