import image from 'assets/services/service_bg.jpg'
import ele1 from 'assets/services/elements/ele.png'
import React, { useEffect, useRef } from 'react'
import { featuresData } from 'static/services'
import { gsap } from 'gsap'
import SeismicShiftMobile from './mobileView'

export default function SeismicShift() {
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const screenWidth = window.innerWidth
    if (screenWidth > 768) {
      const cards = document.querySelectorAll('.seismic-shift-wrapper .card')
      console.log(cards)
      const ctx = gsap.context(() => {
        const tm = gsap.timeline({
          scrollTrigger: {
            trigger: '.seismic-shift-wrapper',
            start: 'top top',
            end: 'bottom top',
            pin: true,
            scrub: 2,
            markers: false,
          },
        })
        cards.forEach((card, index) => {
          const value = (10 - index / 2) / 10

          console.log(value)
          tm.fromTo(
            card,
            { scale: 1.3, y: '160vh' },
            { scale: 1, y: 0, transformOrigin: 'top', duration: 3 },
          )
        })
      }, container)
      return () => ctx.revert()
    }
  }, [])
  return (
    <section>
      {/* Desktop View */}
      <div ref={container} className="md:block hidden">
        <div className="seismic-shift-wrapper bg-[url(assets/services/service_bg.jpg)] bg-no-repeat bg-cover  relative md:h-screen overflow-hidden blade-bottom-padding-xl bg-center">
          <div className="grid gap-6 px-4">
            {featuresData.map((item, index) => {
              return (
                <div
                  key={item.title}
                  className={`shadow card card${index} z-${index} absolute inset-x-0 xlg:bottom-16 lg:bottom-10 bottom-6 2xl:min-h-[650px] min-h-[450px] bg-gradient 2xl:w-9/12 w-10/12   z-10  rounded-xl overflow-hidden lightGray mx-auto blade-top-padding blade-bottom-padding`}
                >
                  <div className="xl:max-w-3xl max-w-2xl text-center mx-auto">
                    <h3 className="titleClass font-semibold leading-tight text-black">
                      {item.title}
                    </h3>
                    <h5 className="subTitleClass font-medium text-darkGray xlg:mt-4 mt-2 leading-normal">
                      {item.subtitle}
                    </h5>
                  </div>
                  <div className="flex md:flex-row flex-col justify-around items-center xlg:px-32 xl:px-20 px-28 gap-10 mx-auto 2xl:pt-10 xlg:pt-10 pt-4">
                    <div className="flex-1 max-w-xl">
                      {item.paragraph ? (
                        <h5 className="font-semibold">{item.paragraph}</h5>
                      ) : (
                        ''
                      )}{' '}
                      <h6 className="mt-2">{item.subParagraph}</h6>
                    </div>
                    <div className="flex justify-center items-center px-20 xlg:max-w-lg lg:max-w-[400px] max-w-[200px] mx-auto flex-1 ">
                      <img
                        className="opacity-80 h-full"
                        src={item.imgSrc}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      {/* Moblie view */}

      <SeismicShiftMobile />
    </section>
  )
}
