import { HeroBtn } from 'atoms/buttons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

type CTA = {
  type: 'primary' | 'secondary'
  text: string
  onClick: () => void
  color: 'transparent' | 'black' | 'blue' | undefined
}
type Subtitle = { type: 'primary' | 'secondary'; value: string }
type Props = {
  title: string
  subtitle?: Array<Subtitle>
  cta?: Array<CTA>
}

export default function SubFooter(props: Props) {
  const { title, subtitle, cta } = props

  const navigate = useNavigate()
  const handleSchedule = () => {
    navigate('/contact-us')
  }
  const handleProposal = () => navigate('/contact-us')
  return (
    <div className="md:blade-bottom-padding-xl">
      <div className="sm:w-10/12 w-full sm:ml-auto  bg-cover bg-center bg-[url('assets/home/subFooter.jpg')]">
        <div className="blade-top-padding-lg blade-bottom-padding-lg sm:w-full w-11/12 px-5">
          <div className=" blade-top-padding-lg blade-bottom-padding-lg blade-top-margin-sm blade-bottom-margin-sm md:w-11/12 lg:pl-10 pl-0 block  sm:ml-auto ">
            {/* <h2 className="bg-darkGradient bg-clip-text text-transparent font-semibold">
              Let&rsquo;s Redefine <br className="md:block hidden" />
              Possibilities Together!
            </h2> */}
            <h2
              className="bg-darkGradient bg-clip-text text-transparent font-semibold"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {subtitle ? (
              <h5
                className="mt-2"
                dangerouslySetInnerHTML={{ __html: subtitle[0].value }}
              />
            ) : (
              ''
            )}
            {cta ? (
              <div className="mt-4">
                <HeroBtn
                  text="Step into a World of Possibilities!"
                  size="base"
                  color="black"
                  onClick={handleSchedule}
                  type="button"
                />
              </div>
            ) : (
              <div className="blade-top-padding-sm">
                <div className="flex flex-col md:flex-row lg:gap-8 gap-2">
                  <HeroBtn
                    text="Schedule a Consultation"
                    size="base"
                    type="button"
                    onClick={handleSchedule}
                    color="transparent"
                  />
                  <div className="lg:border-1 border-dashed border-dark" />
                  <HeroBtn
                    text="Request a Proposal"
                    size="base"
                    color="black"
                    onClick={handleProposal}
                    type="button"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
